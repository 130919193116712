<template>
  <c-box w="full">
    <Breadcrumbs :items="breadcrumbItems" />
    <hr>
    <c-box mt="30px">
      <c-text
        color="primary.400"
        text-transform="uppercase"
      >
        Detail Program
      </c-text>
      <c-text
        font-size="24px"
        font-weight="700"
      >
        Kuesioner
      </c-text>
    </c-box>
    <c-box
      v-if="kuisioner"
      mt="30px"
      border="1px solid #008C81"
      rounded="lg"
      p="20px"
    >
      <c-flex
        v-chakra="{
          '.step': {
            w: '100%',
            h: '8px',
            maxWidth: '156px',
            borderRadius: '20px',
            backgroundColor: 'superLightGray.900',
            marginRight: '12px',
            ':last-child': {
              marginRight: '0',
            },
            '&.done': {
              backgroundColor: 'primary.400',
            },
          },
        }"
        w="800px"
        mt="30px"
        justify-content="space-between"
        align-items="center"
      >
        <c-box
          v-for="(_, index) in 7"
          :key="index"
          class="step"
          :class="{ done: index < page }"
        />
      </c-flex>

      <c-text
        v-if="selectedQuizionary"
        mt="20px"
        font-weight="bold"
        font-size="18px"
        line-height="150%"
      >
        {{ selectedQuizionary.title }}
      </c-text>

      <QuizionaryDetail
        v-if="selectedQuizionary && page !== 5"
        v-chakra
        :data="selectedQuizionary.data"
        mt="20px"
      />
      <template v-if="selectedQuizionary && page === 5">
        <Quizionary5Detail
          v-for="item in selectedQuizionary.data"
          :key="item.value.id"
          v-chakra
          :animal-side-dish="item.value.animalSideDish"
          :category="item.value.category"
          :drink="item.value.drink"
          :fruit="item.value.fruit"
          :photo-url="item.value.photoUrl"
          :snack="item.value.snack"
          :staple-food="item.value.stapleFood"
          :vegetable="item.value.vegetable"
          :vegetable-side-dish="item.value.vegetableSideDish"
          mt="10px"
        />
      </template>

      <hr
        v-if="isDevelopment"
        v-chakra
        mt="30px"
        w="full"
      >
      <QuizionaryDetail
        v-if="selectedQuizionary && selectedQuizionary.dinamis"
        v-chakra
        :data="selectedQuizionary.dinamis"
        mt="20px"
      />

      <c-box
        d="flex"
        mt="30px"
        justify-content="space-between"
      >
        <c-button
          d="flex"
          grid-gap="5px"
          px="20px"
          rounded="full"
          variant-color="primary"
          :is-disabled="page === 1"
          as="router-link"
          :to="{ query: { page: page - 1 } }"
        >
          <c-icon
            name="chevron-left"
            size="24px"
          />
          <c-text>Kembali</c-text>
        </c-button>
        <c-button
          d="flex"
          grid-gap="5px"
          px="20px"
          rounded="full"
          variant-color="primary"
          :is-disabled="page === 7"
          as="router-link"
          :to="{ query: { page: page + 1 } }"
        >
          <c-text>Selanjutnya</c-text>
          <c-icon
            name="chevron-right"
            size="24px"
          />
        </c-button>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import _ from 'lodash'
import Breadcrumbs from '@/components/breadcrumb.vue'
import QuizionaryDetail from '@/components/quizionary/detail.vue'
import Quizionary5Detail from '@/components/quizionary/detail-kuis-5.vue'
import quizionaryLabelMap from '@/utils/quizionary-label-map.js'
import quizionaryValueMap from '@/utils/quizionary-value-map'
import { sortMealCategory } from '@/utils/sort-meal-category'

export default {
  name: 'SUClientsQuizionaryIndex',
  components: { Breadcrumbs, QuizionaryDetail, Quizionary5Detail },
  data() {
    return {
      kuisioner: null,
    }
  },
  computed: {
    programId() {
      return this.$route.params.programId
    },
    breadcrumbItems() {
      return [
        {
          label: 'Manajemen Klien',
          href: this.$router.resolve({ name: 'superadmin.clients' }).href,
        },
        {
          label: 'Detail Klien',
          href: this.$router.resolve({
            name: 'superadmin.clients-detail',
          }).href,
        },
        {
          label: 'Detail Program',
          href: this.$router.resolve({ name: 'superadmin.clients-programs' })
            .href,
        },
        {
          label: 'Detail Kuesioner',
          href: null,
          isCurrent: true,
        },
      ]
    },
    page() {
      return +(this.$route.query.page ?? 1)
    },
    selectedQuizionary() {
      return this.quizionary[this.page]
    },
    quizionary() {
      let page5 = [...this.kuisioner.foodRecords].sort((a, b) =>
        sortMealCategory(a.category, b.category),
      )

      /*
    anthropometry_data: (2) [{…}, {…}]
    daily_activities: [{…}]
    diet_and_eating_history: (2) [{…}, {…}]
    food_records: (3) [{…}, {…}, {…}]
    general_data: (2) [{…}, {…}]
    informed_consent: (2) [{…}, {…}]
    medical_history: (2) [{…}, {…}]
    physical_activity_abilities: (2) [{…}, {…}]
     */

      return {
        1: {
          title: 'Identitas & Data Umum',
          data: Object.entries(_.omit(this.kuisioner.generalData, 'id')).map(
            ([key, value], index) => ({
              order: index,
              label: quizionaryLabelMap[key] ?? key,
              value: quizionaryValueMap[key]?.(value) ?? value,
            }),
          ),
          dinamis: this.getDinamisQuestionFor('general_data'),
        },
        2: {
          title: 'Data Antropometri (Ukuran & Komposisi Tubuh)',
          data: Object.entries(
            _.omit(this.kuisioner.anthropometryData, 'id'),
          ).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor('anthropometry_data'),
        },
        3: {
          title: 'Data Pola & Riwayat Makan',
          data: Object.entries(
            _.omit(this.kuisioner.dietAndEatingHistory, 'id'),
          ).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor('diet_and_eating_history'),
        },
        4: {
          title: 'Data Aktivitas Harian',
          data: Object.entries(
            _.omit(this.kuisioner.dailyActivities, 'id'),
          ).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor('daily_activities'),
        },
        5: {
          title: 'Food Record 24 Jam',
          data: Object.entries(_.omit(page5, 'id')).map(
            ([key, value], index) => ({
              order: index,
              label: quizionaryLabelMap[key] ?? key,
              value: quizionaryValueMap[key]?.(value) ?? value,
            }),
          ),
          dinamis: this.getDinamisQuestionFor('food_records'),
        },
        6: {
          title: 'Kemampuan Aktivitas Fisik',
          data: Object.entries(
            _.omit(this.kuisioner.physicalActivityAbilities, 'id'),
          ).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor('physical_activity_abilities'),
        },
        7: {
          title: 'Kondisi Pribadi & Riwayat Medis',
          data: Object.entries(
            _.omit(this.kuisioner.medicalHistory, 'id', 'specialMedicine'),
          ).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor('medical_history'),
        },
      }
    },
  },
  async mounted() {
    let prefix = this.authRole.replace('_', '-')
    const r = await this.axios
      .get(`/v1/${prefix}/quizioner/${this.programId}`)
      .then((r) => r.data.data)
    this.kuisioner = r
  },
  methods: {
    getDinamisQuestionFor(category) {
      return this.kuisioner.answers.filter(it => it.questions.category === category)
        .map((item, index) => ({
          order: index,
          label: item.question,
          value: item.answer,
        }))
    },
  },
}
</script>
