var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "full"
    }
  }, [_c('Breadcrumbs', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('hr'), _c('c-box', {
    attrs: {
      "mt": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "text-transform": "uppercase"
    }
  }, [_vm._v(" Detail Program ")]), _c('c-text', {
    attrs: {
      "font-size": "24px",
      "font-weight": "700"
    }
  }, [_vm._v(" Kuesioner ")])], 1), _vm.kuisioner ? _c('c-box', {
    attrs: {
      "mt": "30px",
      "border": "1px solid #008C81",
      "rounded": "lg",
      "p": "20px"
    }
  }, [_c('c-flex', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '.step': {
          w: '100%',
          h: '8px',
          maxWidth: '156px',
          borderRadius: '20px',
          backgroundColor: 'superLightGray.900',
          marginRight: '12px',
          ':last-child': {
            marginRight: '0'
          },
          '&.done': {
            backgroundColor: 'primary.400'
          }
        }
      },
      expression: "{\n        '.step': {\n          w: '100%',\n          h: '8px',\n          maxWidth: '156px',\n          borderRadius: '20px',\n          backgroundColor: 'superLightGray.900',\n          marginRight: '12px',\n          ':last-child': {\n            marginRight: '0',\n          },\n          '&.done': {\n            backgroundColor: 'primary.400',\n          },\n        },\n      }"
    }],
    attrs: {
      "w": "800px",
      "mt": "30px",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, _vm._l(7, function (_, index) {
    return _c('c-box', {
      key: index,
      staticClass: "step",
      class: {
        done: index < _vm.page
      }
    });
  }), 1), _vm.selectedQuizionary ? _c('c-text', {
    attrs: {
      "mt": "20px",
      "font-weight": "bold",
      "font-size": "18px",
      "line-height": "150%"
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedQuizionary.title) + " ")]) : _vm._e(), _vm.selectedQuizionary && _vm.page !== 5 ? _c('QuizionaryDetail', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "data": _vm.selectedQuizionary.data,
      "mt": "20px"
    }
  }) : _vm._e(), _vm.selectedQuizionary && _vm.page === 5 ? _vm._l(_vm.selectedQuizionary.data, function (item) {
    return _c('Quizionary5Detail', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      key: item.value.id,
      attrs: {
        "animal-side-dish": item.value.animalSideDish,
        "category": item.value.category,
        "drink": item.value.drink,
        "fruit": item.value.fruit,
        "photo-url": item.value.photoUrl,
        "snack": item.value.snack,
        "staple-food": item.value.stapleFood,
        "vegetable": item.value.vegetable,
        "vegetable-side-dish": item.value.vegetableSideDish,
        "mt": "10px"
      }
    });
  }) : _vm._e(), _vm.isDevelopment ? _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "30px",
      "w": "full"
    }
  }) : _vm._e(), _vm.selectedQuizionary && _vm.selectedQuizionary.dinamis ? _c('QuizionaryDetail', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "data": _vm.selectedQuizionary.dinamis,
      "mt": "20px"
    }
  }) : _vm._e(), _c('c-box', {
    attrs: {
      "d": "flex",
      "mt": "30px",
      "justify-content": "space-between"
    }
  }, [_c('c-button', {
    attrs: {
      "d": "flex",
      "grid-gap": "5px",
      "px": "20px",
      "rounded": "full",
      "variant-color": "primary",
      "is-disabled": _vm.page === 1,
      "as": "router-link",
      "to": {
        query: {
          page: _vm.page - 1
        }
      }
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-left",
      "size": "24px"
    }
  }), _c('c-text', [_vm._v("Kembali")])], 1), _c('c-button', {
    attrs: {
      "d": "flex",
      "grid-gap": "5px",
      "px": "20px",
      "rounded": "full",
      "variant-color": "primary",
      "is-disabled": _vm.page === 7,
      "as": "router-link",
      "to": {
        query: {
          page: _vm.page + 1
        }
      }
    }
  }, [_c('c-text', [_vm._v("Selanjutnya")]), _c('c-icon', {
    attrs: {
      "name": "chevron-right",
      "size": "24px"
    }
  })], 1)], 1)], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }