var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": "column"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-size": "21px"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("getMenuCategory")(_vm.category)) + " ")]), _vm.photoUrl != null ? [_c('c-link', {
    attrs: {
      "href": _vm.photoUrl,
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  }, [_c('c-image', {
    attrs: {
      "h": "250px",
      "w": "400px",
      "src": _vm.photoUrl,
      "object-fit": "contain"
    }
  })], 1)] : _vm.snack != null ? [_c('c-text', [_vm._v("Snack")]), _c('c-text', [_vm._v(_vm._s(_vm.snack))])] : [_c('c-text', [_vm._v("Makanan Pokok atau Sumber Karbohidrat")]), _c('c-text', [_vm._v(_vm._s(_vm.stapleFood))]), _c('c-text', [_vm._v("Lauk hewani")]), _c('c-text', [_vm._v(_vm._s(_vm.animalSideDish))]), _c('c-text', [_vm._v("Lauk nabati")]), _c('c-text', [_vm._v(_vm._s(_vm.vegetableSideDish))]), _c('c-text', [_vm._v("Sayur")]), _c('c-text', [_vm._v(_vm._s(_vm.vegetable))]), _c('c-text', [_vm._v("Buah")]), _c('c-text', [_vm._v(_vm._s(_vm.fruit))]), _c('c-text', [_vm._v("Minuman")]), _c('c-text', [_vm._v(_vm._s(_vm.drink))])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }